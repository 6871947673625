import React, { useRef, useEffect, useState, Fragment } from 'react';
import { default as letter_logo } from '../../images/logo_only_letters_white.svg';
import { SiweAuth } from '../auth/siwe-auth/siwe-auth';
import { default as logo_dark_letter } from '../../images/logo_dark_letter.svg';
import { Dialog, Transition } from '@headlessui/react';
import {
  selectGenerateImages,
  selecObservableTrigger,
  syncGenerateSessionAsync,
  generateSessionImagesAsync,
  selectInstrument,
  selectPageLoadState,
  moveImageAsync,
  selectCollectionIdentifier,
  resetGenerateStateSignOut,
  setSessionState,
  selectSessionState,
  syncGenerateDoesSessionExistAsync,
} from './generate-images-slice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  DisplayImageContent,
  GenerateImageDto,
  GenerateInstrumentDto,
  GridPoint,
  MoveWithContentIdDto,
} from './types';
import {
  XMarkIcon,
  FireIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowUpIcon,
  ArrowDownIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  InformationCircleIcon,
  Cog8ToothIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/20/solid';
import { EEvents, EReducerState } from '../../app/enum';
import Emitter from '../../app/emitter';
import { DLinkList } from 'd-link-list';
import {
  EGenerateImageType,
  EGenerateSessionState,
  EMoveGridDirection,
} from './enum';
import { GenerateLoading } from './generate-loading';
import { ImageCard } from './ImageCard';
import { concat, filter, find } from 'lodash';
import { GAuth } from '../auth/gauth/GAuth';
import { selectSiweIsLoggedIn } from '../auth/siwe-auth/siwe-auth-slice';
import { logout, selectGauthIsLoggedIn } from '../auth/gauth/gauth-slice';
import { SignOutModal } from '../header';
import {
  Glossary,
  Instrument,
  MobileInstrument,
  ModalBase,
  SettingsModal,
  ViewImageModal,
} from '../../components';
import {
  selectImageShape,
  selectImageSize,
  selectParentInfluence,
  selectTheme,
} from '../userPrefs/userPrefs-slice';
import { getImageData, isPhoneRes, scrollToImage } from '../../utils';
import { Button } from 'flowbite-react';
import { useQuery } from '@tanstack/react-query';
import {
  fetchInstruments,
  mapInstrumentsToLoadouts,
} from '../../utils/instrument';
import { GenerateImageModalProps } from '../../components/ViewImageModal';
import {
  PaymentType,
  creditsPaymentUrl,
  fetchPaymentInfo,
} from '../../utils/payment';

export type ModalInfo = {
  isOpen: boolean;
  displayImages?: DisplayImageContent[];
  imageList?: DLinkList<DisplayImageContent>;
};

type useStateInstrumentModalInfo = React.Dispatch<
  React.SetStateAction<InstrumentModalInfo>
>;

type InstrumentModalInfo = {
  isOpen: boolean;
};

type GenerateInstrumentModalProps = {
  instrumentModal: InstrumentModalInfo;
  setInstrumentModal: useStateInstrumentModalInfo;
};

type GenerateImageComponentProps = {
  imageModalProps: GenerateImageModalProps;
  instrumentModalProps: GenerateInstrumentModalProps;
  moveModalProps: GenerateImageModalProps;
  selectedImages: Array<ImagePosition>;
  onSelectImages: React.Dispatch<React.SetStateAction<Array<ImagePosition>>>;
};

export function GenerateImages() {
  const modalInfo: ModalInfo = {
    isOpen: false,
  };
  const instrumentModalInfo: InstrumentModalInfo = {
    isOpen: false,
  };
  const moveModalInfo: ModalInfo = {
    isOpen: false,
  };

  const [openModal, setOpenModal] = useState(modalInfo);
  const [instrumentModal, setInstrumentModal] = useState(instrumentModalInfo);
  const [moveModal, setMoveModal] = useState(moveModalInfo);
  const [selectedImages, setSelectedImages] = useState<Array<ImagePosition>>(
    [],
  );

  const dispatch = useAppDispatch();
  const sessionState: EGenerateSessionState =
    useAppSelector(selectSessionState);
  const theme = useAppSelector(selectTheme);

  useEffect(() => {
    dispatch(syncGenerateDoesSessionExistAsync());

    const handleBeforeUnload = (e: any) => {
      var confirmationMessage = 'o/';

      (e || window.event).returnValue = confirmationMessage; //Gecko + IE
      return confirmationMessage; //Webkit, Safari, Chrome
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const clearSelectionHandler = () => setSelectedImages([]);

  return (
    <div className={theme}>
      {sessionState === EGenerateSessionState.NONE && <GenerateLoading />}
      {sessionState === EGenerateSessionState.CHOOSING && <SelectSessionType />}
      {(sessionState === EGenerateSessionState.ACTIVE ||
        sessionState === EGenerateSessionState.BLANK_SLATE ||
        sessionState === EGenerateSessionState.INITIALIZING_COLLECTION) && (
        <>
          <GenerateImagesComponent
            imageModalProps={{
              openModal,
              setOpenModal,
              clearSelectionCallback: clearSelectionHandler,
            }}
            instrumentModalProps={{ instrumentModal, setInstrumentModal }}
            moveModalProps={{
              openModal: moveModal,
              setOpenModal: setMoveModal,
              clearSelectionCallback: clearSelectionHandler,
            }}
            selectedImages={selectedImages}
            onSelectImages={setSelectedImages}
          />
          <ViewImageModal
            openModal={openModal}
            setOpenModal={setOpenModal}
            clearSelectionCallback={clearSelectionHandler}
          />
          <GenerateMoveModal
            openModal={moveModal}
            setOpenModal={setMoveModal}
            clearSelectionCallback={clearSelectionHandler}
          />
        </>
      )}
    </div>
  );
}

export function SelectSessionType() {
  const dispatch = useAppDispatch();
  const [showSignOutModal, setShowSignOutModal] = useState(false);
  const isSiweLoggedIn = useAppSelector(selectSiweIsLoggedIn);
  const isGauthLoggedIn = useAppSelector(selectGauthIsLoggedIn);

  const handleSignOut = (confirm: boolean) => {
    if (confirm) {
      dispatch(logout());
      Emitter.emit(EEvents.ADDRESS_CHANGED, null);
    }

    setShowSignOutModal(false);
  };
  const handleGauthSignOutClick = () => setShowSignOutModal(true);

  return (
    <>
      <header className="absolute inset-x-0 top-0 z-30">
        <nav
          className="flex items-center justify-between p-6 lg:px-8"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <div>
              <span className="sr-only">nilor</span>
              <img
                className="h-14 w-auto"
                src={letter_logo}
                alt=""
                height={256}
                width={256}
              />
            </div>
          </div>
          {!isGauthLoggedIn && (
            <div className="pt-1 pb-1">
              <SiweAuth />
            </div>
          )}
          {!isSiweLoggedIn && (
            <div className="pt-1 pb-1">
              <GAuth onSignOut={handleGauthSignOutClick} />
            </div>
          )}
        </nav>
      </header>
      <div className="bg-zinc-900 px-6 py-24 sm:py-32 lg:px-8 lg:pb-40">
        <div className="mx-auto max-w-2xl text-center content-center">
          <h2 className="text-6xl font-bold tracking-tight text-red-400 pt-15">
            Start Exploration From:
          </h2>
          <div className="mt-4 flex justify-center items-center flex-shrink-0 md:ml-4 md:mt-0 pt-20">
            <button
              type="button"
              className="ml-3 inline-flex items-center rounded-md bg-indigo-500 p-5 text-4xl font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              onClick={() =>
                dispatch(setSessionState(EGenerateSessionState.BLANK_SLATE))
              }
            >
              Blank Slate
            </button>
          </div>
          <div className="mt-4 flex justify-center items-center flex-shrink-0 md:ml-4 md:mt-0 pt-10">
            <button
              type="button"
              className="ml-3 inline-flex items-center rounded-md bg-green-700 px-11 py-5 text-4xl font-semibold text-white shadow-sm hover:bg-green-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              onClick={() => {
                dispatch(setSessionState(EGenerateSessionState.ACTIVE));
              }}
            >
              Resume
            </button>
          </div>
        </div>
      </div>
      <SignOutModal showModal={showSignOutModal} callback={handleSignOut} />
    </>
  );
}

export interface ImagePosition {
  id: string;
  colNum: number;
  rowNum: number;
}

enum HeaderVisibility {
  FULL,
  // PARTIAL,
  NONE,
}

export function GenerateImagesComponent({
  imageModalProps,
  moveModalProps,
  selectedImages,
  onSelectImages,
}: GenerateImageComponentProps) {
  const dispatch = useAppDispatch();
  const images: DLinkList<DLinkList<DisplayImageContent>> =
    useAppSelector(selectGenerateImages);
  const selectedImagesData = getImageData(images, selectedImages);
  const instrument: GenerateInstrumentDto = useAppSelector(selectInstrument);
  const pageState: EReducerState = useAppSelector(selectPageLoadState);
  const imageSize: number = useAppSelector(selectImageSize);

  //WARNING, this is a hack to force a rerender when the observable trigger changes
  const observableTrigger: number = useAppSelector(selecObservableTrigger);

  const divRef = useRef<HTMLDivElement>(null);
  const [showHeader, setShowHeader] = useState<HeaderVisibility>(
    HeaderVisibility.FULL,
  );
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [glossaryOpen, setGlossaryOpen] = useState(false);

  // queries
  const { data: instrumentsData } = useQuery({
    queryKey: ['instruments'],
    queryFn: fetchInstruments,
  });

  const {
    data: paymentData,
    isLoading: paymentDataLoading,
    // isError: paymentDataError,
  } = useQuery({
    queryKey: ['paymentInfo'],
    queryFn: fetchPaymentInfo,
  });
  //

  const loadouts = mapInstrumentsToLoadouts(instrumentsData);
  const activeLoadout = find(loadouts, { id: instrument.id }) || loadouts[0];
  const showBuyCredits =
    !paymentDataLoading &&
    paymentData.paymentType !== PaymentType.FREE &&
    paymentData.credits <= 0;

  const onAddressChange = () => {
    dispatch(resetGenerateStateSignOut());
  };

  const onFocusOnGridImage = (point: GridPoint) => {
    scrollToImage(point.x, point.y);
  };

  useEffect(() => {
    Emitter.on(EEvents.ADDRESS_CHANGED, onAddressChange);
    Emitter.on(EEvents.FOCUS_ON_GRID_IMAGE, onFocusOnGridImage);
    return () => {
      Emitter.off(EEvents.ADDRESS_CHANGED, onAddressChange);
      Emitter.off(EEvents.FOCUS_ON_GRID_IMAGE, onFocusOnGridImage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(syncGenerateSessionAsync());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectHandler = (isSelected: boolean, imagePos: ImagePosition) => {
    const updatedImages = isSelected
      ? concat(selectedImages, imagePos)
      : filter(selectedImages, (image) => image.id !== imagePos.id);

    onSelectImages(updatedImages);
  };

  const getShowHeaderVal = (headerVis: HeaderVisibility): HeaderVisibility => {
    switch (headerVis) {
      case HeaderVisibility.FULL:
        return HeaderVisibility.NONE;
      // case HeaderVisibility.PARTIAL:
      //   return HeaderVisibility.NONE;
      case HeaderVisibility.NONE:
        return HeaderVisibility.FULL;
    }
  };

  return (
    <>
      {pageState === EReducerState.IDLE && (
        <div className="h-screen-flex flex flex-col min-h-screen">
          <div className="sticky top-0 z-40 bg-app-secondary rounded-b-lg drop-shadow-xl">
            {[HeaderVisibility.FULL].includes(showHeader) && (
              <div className="overflow-y-hidden pt-4">
                <GenerateHeader />
              </div>
            )}
            <SummonNotification />
            <div
              className="flex justify-center relative ml-auto mr-auto h-6 text-white cursor-pointer"
              onClick={() => setShowHeader(getShowHeaderVal(showHeader))}
            >
              {showHeader === HeaderVisibility.FULL ? (
                <ChevronDownIcon />
              ) : (
                <ChevronUpIcon />
              )}
            </div>
            {showBuyCredits && (
              <div className="flex justify-center items-center gap-3 relative text-white bg-red-500 py-2">
                <ExclamationTriangleIcon className="w-6" />
                <span>You're out of credits.</span>
                <a
                  href={creditsPaymentUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-700"
                >
                  Click here to get more.
                </a>
              </div>
            )}
          </div>
          <ul
            className="flex gap-4 overflow-auto p-4 mb-96"
            ref={divRef as any}
          >
            {images.map((displayList, displayListIndex) => (
              <div
                className="flex flex-col shrink-0 gap-4"
                key={`image-column-${displayListIndex}`}
              >
                {displayList.map((displayImage, displayImageIndex) => {
                  return (
                    <li key={displayImage.content.id}>
                      <ImageCard
                        image={displayImage}
                        listIndex={displayListIndex}
                        imageIndex={displayImageIndex}
                        imageList={displayList}
                        imageModalProps={imageModalProps}
                        instrument={instrument}
                        moveModalProps={moveModalProps}
                        selectCallback={selectHandler}
                        isSelected={
                          !!find(selectedImages, {
                            id: displayImage.content.id,
                          })
                        }
                        imageSize={imageSize}
                        imageRatio={displayImage.content.contentRatio}
                      />
                    </li>
                  );
                })}
              </div>
            ))}
          </ul>
          {!isPhoneRes() && (
            <div className="fixed bottom-4 left-1/2 translate-x-[-50%]">
              <Instrument
                selectedImages={selectedImages}
                onSelectImages={onSelectImages}
                selectedImagesData={selectedImagesData}
                loadout={activeLoadout}
                moveModalProps={moveModalProps}
                showTopDrawer={false}
              />
            </div>
          )}
          {isPhoneRes() && (
            <div className="fixed bottom-0 w-full">
              <MobileInstrument
                selectedImages={selectedImages}
                onSelectImages={onSelectImages}
                selectedImagesData={selectedImagesData}
                loadout={activeLoadout}
                moveModalProps={moveModalProps}
              />
            </div>
          )}
          {!isPhoneRes() && (
            <div className="fixed bottom-4 right-4 flex justify-end gap-3">
              <div
                className={`instrument-button-large h-12 w-12 bg-instrument-bottomdrawer-upload text-white hover:bg-white hover:text-instrument-bottomdrawer-upload`}
                title="Settings"
                onClick={(e) => {
                  e.stopPropagation();
                  setSettingsOpen(true);
                }}
              >
                <Cog8ToothIcon />
              </div>
              <div
                className={`instrument-button-large h-12 w-12 bg-instrument-bottomdrawer-upload text-white hover:bg-white hover:text-instrument-bottomdrawer-upload`}
                title="Glossary"
                onClick={(e) => {
                  e.stopPropagation();
                  setGlossaryOpen(true);
                }}
              >
                <InformationCircleIcon />
              </div>
            </div>
          )}
        </div>
      )}
      {/* Modal for Glossary */}
      <ModalBase
        title="Glossary"
        body={<Glossary />}
        footer={
          <Button
            className="w-1/2"
            color="dark"
            onClick={() => setGlossaryOpen(false)}
          >
            Close
          </Button>
        }
        show={glossaryOpen}
        onClose={() => setGlossaryOpen(false)}
      />
      {settingsOpen && <SettingsModal onClose={() => setSettingsOpen(false)} />}
      {pageState === EReducerState.LOADING && (
        <div>
          <GenerateLoading />
        </div>
      )}
    </>
  );
}

export default function GenerateHeader() {
  //TODO Fire event on summon clicked!
  const dispatch = useAppDispatch();
  const [showSignOutModal, setShowSignOutModal] = useState(false);
  const [summonValue, setSummonValue] = useState('');
  const isSiweLoggedIn = useAppSelector(selectSiweIsLoggedIn);
  const isGauthLoggedIn = useAppSelector(selectGauthIsLoggedIn);
  const currentCollection = useAppSelector(selectCollectionIdentifier);
  const imageShape = useAppSelector(selectImageShape);
  const parentInfluence = useAppSelector(selectParentInfluence);

  const handleSignOut = (confirm: boolean) => {
    if (confirm) {
      dispatch(logout());
      Emitter.emit(EEvents.ADDRESS_CHANGED, null);
    }

    setShowSignOutModal(false);
  };
  const handleGauthSignOutClick = () => setShowSignOutModal(true);

  const handleSummonChange = (event: any) => {
    setSummonValue(event.target.value);
  };

  const handleSummonClick = () => {
    const prompt: string = summonValue;
    setSummonValue('');
    const generateDto: GenerateImageDto = {
      prompt: prompt,
      type: EGenerateImageType.SUMMON,
      parentInfluence: parentInfluence,
      imageShape,
    };
    dispatch(generateSessionImagesAsync(generateDto));
  };

  return (
    <div className="px-2">
      <div className="mt-2 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <div className="relative mt-0 rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-1">
              <img
                className=" h-14 w-auto"
                src={logo_dark_letter}
                alt=""
                height={256}
                width={256}
                aria-hidden="true"
              />
            </div>
            <input
              type="summon"
              name="summon"
              id="summon"
              className="block w-full rounded-md border-0 py-1.5 pl-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="Type in something to explore..."
              value={summonValue}
              onChange={handleSummonChange}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSummonClick();
                }
              }}
            />
          </div>
        </div>
        <div className="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
          <button
            type="button"
            className="inline-flex items-center rounded-md bg-button-secondary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-300"
            onClick={handleSummonClick}
          >
            Summon
          </button>
          <div
            className="ml-2 p-2 rounded-xl text-white truncate max-w-[200px]"
            title={currentCollection.name}
          >
            {currentCollection.name}
          </div>
          {!isGauthLoggedIn && (
            <div className="pl-2">
              <SiweAuth />
            </div>
          )}
          {!isSiweLoggedIn && <GAuth onSignOut={handleGauthSignOutClick} />}
        </div>
      </div>
      <SignOutModal showModal={showSignOutModal} callback={handleSignOut} />
    </div>
  );
}

export function GenerateMoveModal({
  openModal,
  setOpenModal,
  clearSelectionCallback,
}: GenerateImageModalProps) {
  const dispatch = useAppDispatch();
  function closeModal() {
    setOpenModal({ isOpen: false, displayImages: openModal.displayImages });
  }

  const { displayImages = [] } = openModal;

  return (
    <Transition.Root show={openModal.isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-20 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full object-contain items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-auto max-w-screen rounded-lg bg-stone-600 px-4 pb-4 pt-5 text-left shadow-xl transition-all">
                <div className="justify-center flex flex-wrap gap-2 pb-2 px-1 items-center mx-auto">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-neutral-900 px-3 py-2 text-lg font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => {
                      displayImages.forEach((displayImage) => {
                        const moveDto: MoveWithContentIdDto = {
                          contentId: displayImage.content.id,
                          moveDirection: EMoveGridDirection.LEFT,
                        };
                        dispatch(moveImageAsync(moveDto));
                      });
                      clearSelectionCallback();
                      setOpenModal({ isOpen: false });
                    }}
                  >
                    <ArrowLeftIcon className="h-6 w-6" />
                  </button>
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-neutral-900 px-3 py-2 text-lg font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => {
                      displayImages.forEach((displayImage) => {
                        const moveDto: MoveWithContentIdDto = {
                          contentId: displayImage.content.id!,
                          moveDirection: EMoveGridDirection.UP,
                        };
                        dispatch(moveImageAsync(moveDto));
                      });
                      clearSelectionCallback();
                      setOpenModal({ isOpen: false });
                    }}
                  >
                    <ArrowUpIcon className="h-6 w-6" />
                  </button>
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-neutral-900 px-3 py-2 text-lg font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => {
                      displayImages.forEach((displayImage) => {
                        const moveDto: MoveWithContentIdDto = {
                          contentId: displayImage.content.id!,
                          moveDirection: EMoveGridDirection.DOWN,
                        };
                        dispatch(moveImageAsync(moveDto));
                      });
                      clearSelectionCallback();
                      setOpenModal({ isOpen: false });
                    }}
                  >
                    <ArrowDownIcon className="h-6 w-6" />
                  </button>
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-neutral-900 px-3 py-2 text-lg font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => {
                      displayImages.forEach((displayImage) => {
                        const moveDto: MoveWithContentIdDto = {
                          contentId: displayImage.content.id!,
                          moveDirection: EMoveGridDirection.RIGHT,
                        };
                        dispatch(moveImageAsync(moveDto));
                      });
                      clearSelectionCallback();
                      setOpenModal({ isOpen: false });
                    }}
                  >
                    <ArrowRightIcon className="h-6 w-6" />
                  </button>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-neutral-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => closeModal()}
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export function SummonNotification() {
  const [show, setShow] = useState(false);
  const [displayRefCount, setDisplayRefCount] = useState(0);
  const generateRefCount = useRef(0);

  //EMITTER
  useEffect(() => {
    Emitter.on(EEvents.INCREMENT_SUMMON_COUNT, onGenerationStarted);
    Emitter.on(EEvents.DECREMENT_SUMMON_COUNT, onGenerationFinished);

    function onGenerationStarted() {
      const count = generateRefCount.current + 1;
      generateRefCount.current = count;
      setDisplayRefCount(count);
      // console.log('onGenerationStarted ', generateRefCount.current);
      setShow(true);
    }
    function onGenerationFinished() {
      const count = Math.max(generateRefCount.current - 1, 0);
      generateRefCount.current = count;
      setDisplayRefCount(count);
      // console.log('onGenerationFinished ', generateRefCount.current);
      if (count === 0) {
        // console.log('onGenerationFinished show false ', count);
        setShow(false);
      }
    }
    return () => {
      Emitter.off(EEvents.INCREMENT_SUMMON_COUNT, onGenerationStarted);
      Emitter.off(EEvents.DECREMENT_SUMMON_COUNT, onGenerationFinished);
    };
  }, [generateRefCount, setDisplayRefCount]);

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="pt-10 pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end xl:pt-10 md:pt-12 lg:pt-10">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-xs rounded-lg bg-violet-300 shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-center">
                  <div className="ml-4 w-4 flex-1 items-center">
                    <span className="inline-flex">
                      <FireIcon className=" h-3 w-3 mt-1 animate-ping-med-forward" />
                      <p className=" pl-3 text-sm lg:text-base font-bold font-mono text-blue-900">
                        <span>
                          Summoning {displayRefCount}{' '}
                          {displayRefCount > 1 ? ' images' : ' image'}
                        </span>
                        <span className="animate-ellipses-reveal ">...</span>
                      </p>
                    </span>
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md bg-violet-500 text-indigo-700 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
