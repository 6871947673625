import { useEffect, useLayoutEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  fetchPartyCollectionAsync,
  pollForPartyUpdatesAsync,
  removePartyContentAsync,
  selectPartyContents,
  voteOnPartyContentAsync,
} from './party-slice';
import { DisplayPartyContent } from './types';
import { ContentVoteDto } from '../vote/types';
import { ECollectionType } from '../generate/enum';
import { EContentVote } from '../vote/enum';
import { RemoveFromCollectionDto } from '../../app/types';
import { useParams } from 'react-router-dom';
import { Button, Spinner } from 'flowbite-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  CollectionRole,
  addUserToCollection,
  fetchCollectionRoles,
  getCollectionRoleOptions,
  handleZipDownload,
} from '../../utils';
import { get, isEmpty } from 'lodash';
import { ViewPartyImageModal } from '../../components';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

export const PartyView = () => {
  const [username, setUsername] = useState('');
  const [submittingAddUser, setSubmittingAddUser] = useState(false);
  const [exportProcessing, setExportProcessing] = useState(false);
  const [selectedUserRole, setSelectedUserRole] = useState<CollectionRole>(
    CollectionRole.EDITOR,
  );
  const [addUserError, setAddUserError] = useState('');
  const [viewImageIndex, setViewImageIndex] = useState(-1);
  const dispatch = useAppDispatch();
  const contents: DisplayPartyContent[] = useAppSelector(selectPartyContents);

  const { collectionId } = useParams();
  if (collectionId === undefined) {
    throw new Error('collectionId is undefined');
  }

  useEffect(() => {
    dispatch(fetchPartyCollectionAsync(collectionId));

    const intervalId = setInterval(() => {
      dispatch(pollForPartyUpdatesAsync(collectionId));
    }, 5000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // hack to prevent scroll to top on re-render
  const scrollX = window.scrollX;
  const scrollY = window.scrollY;
  useLayoutEffect(() => {
    window.scrollTo(scrollX, scrollY);
  });

  // queries
  const {
    data: collectionRolesData,
    // isLoading: collectionRolesLoading,
    // isError: collectionRolesError,
  } = useQuery({
    queryKey: ['explorer-collection-roles', collectionId],
    queryFn: () => fetchCollectionRoles(collectionId),
  });

  // mutations
  const submitConsultationMutation = useMutation({
    mutationFn: addUserToCollection,
    onMutate: () => {
      setSubmittingAddUser(true);
    },
    onSuccess: () => {
      setSubmittingAddUser(false);
      setUsername('');
    },
    onError: (error) => {
      const message = get(
        error,
        'response.data.message',
        'Error adding user to collection',
      );
      setSubmittingAddUser(false);
      setAddUserError(message);
    },
  });

  const handleAddUser = () => {
    setAddUserError('');
    submitConsultationMutation.mutate({
      collectionId: collectionId,
      username: username,
      role: selectedUserRole,
    });
  };

  const handleExport = () => {
    handleZipDownload(
      contents.map((c) => c.content.contentUrl),
      setExportProcessing,
    );
  };

  const addUserDisabled = submittingAddUser || !username;
  const explorerRoles =
    collectionRolesData?.map((role: any) => role.role) || [];
  const showAddUser =
    explorerRoles.includes(CollectionRole.ADMIN) ||
    explorerRoles.includes(CollectionRole.EDITOR);
  const availableRoles = explorerRoles.includes(CollectionRole.ADMIN)
    ? getCollectionRoleOptions()
    : getCollectionRoleOptions().filter(
        (role) => role.value === CollectionRole.EDITOR,
      );
  const contentsLoaded = !isEmpty(contents);

  return (
    <div>
      {!contentsLoaded && (
        <div className="flex justify-center items-center w-full h-screen">
          <Spinner size="xl" />
        </div>
      )}
      <div className="mx-auto max-w-2xl px-4 pt-4 pb-16 sm:px-6 lg:max-w-7xl lg:px-8">
        {contentsLoaded && showAddUser && (
          <>
            <label className="text-white text-lg">Add Explorer:</label>
            <div className="flex gap-2 items-center w-full md:w-[600px]">
              <input
                type="text"
                className={`flex-grow rounded-md p-2 ${
                  submittingAddUser ? 'bg-gray-200 cursor-not-allowed' : ''
                }`}
                value={username}
                placeholder="explorer username"
                disabled={submittingAddUser}
                onChange={(e) => setUsername(e.target.value)}
              />
              <select
                id="theme"
                name="theme"
                className="block w-24 rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                defaultValue={selectedUserRole}
                onChange={(e) =>
                  setSelectedUserRole(e.target.value as CollectionRole)
                }
              >
                {availableRoles.map((role) => (
                  <option key={`role-${role.value}`} value={role.value}>
                    {role.label}
                  </option>
                ))}
              </select>
              <Button
                className={`${
                  addUserDisabled ? '' : 'active:scale-75'
                } transition-transform w-24`}
                color="blue"
                disabled={addUserDisabled}
                onClick={handleAddUser}
              >
                {submittingAddUser ? <Spinner /> : 'Submit'}
              </Button>
            </div>
            <p className="text-red-500 mb-4">{addUserError}</p>
          </>
        )}
        {contentsLoaded && (
          <Button
            className={`${
              exportProcessing ? '' : 'active:scale-75'
            } transition-transform w-full md:w-auto mb-4 bg-green-500 text-white hover:text-green-500`}
            color="green"
            disabled={exportProcessing}
            onClick={handleExport}
          >
            {exportProcessing ? <Spinner /> : 'Export Collection'}
          </Button>
        )}
        <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8">
          {contentsLoaded &&
            contents.map((image, idx) => (
              <div
                key={image.content.id}
                className="group relative flex flex-col gap-2 overflow-hidden rounded-lg border border-gray-200 bg-stone-800 p-3"
              >
                <img
                  src={image.imageData}
                  alt={image.content.prompt}
                  className="h-[384px] object-contain hover:opacity-75 cursor-pointer"
                  onClick={() => setViewImageIndex(idx)}
                />
                {image.content.nft?.name}
                <p className="text-sm text-gray-300 bg-stone-800 bg-opacity-70 min-h-[160px]">
                  {image.content.prompt}
                </p>
                <p className="text-base font-medium text-gray-100 flex items-center justify-center border-2 border-gray-300 bg-stone-800 rounded-full w-12 h-12">
                  {image.content.coolRanking}
                </p>
                <span className="isolate inline-flex rounded-md shadow-sm">
                  <button
                    type="button"
                    className="relative inline-flex items-center rounded-l-md bg-red-500 px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                    onClick={() => {
                      const removeContent: RemoveFromCollectionDto = {
                        collectionId: collectionId,
                        contentId: image.content.id,
                        collectionType: ECollectionType.PARTY,
                      };
                      dispatch(removePartyContentAsync(removeContent));
                    }}
                  >
                    delete
                  </button>
                  {image.content.explorerVote !== EContentVote.NOT_COOL && (
                    <button
                      type="button"
                      className="relative -ml-px inline-flex items-center bg-orange-300 px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                      onClick={() => {
                        const coolVote: ContentVoteDto = {
                          collectionId: collectionId,
                          contentId: image.content.id,
                          collectionType: ECollectionType.PARTY,
                          vote: EContentVote.NOT_COOL,
                        };
                        dispatch(voteOnPartyContentAsync(coolVote));
                      }}
                    >
                      not cool
                    </button>
                  )}
                  {image.content.explorerVote !== EContentVote.COOL && (
                    <button
                      type="button"
                      className={classNames(
                        image.content.explorerVote === EContentVote.ULTRA_COOL
                          ? 'rounded-r-md'
                          : '',
                        'relative -ml-px inline-flex items-center bg-lime-200 px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10',
                      )}
                      onClick={() => {
                        const coolVote: ContentVoteDto = {
                          collectionId: collectionId,
                          contentId: image.content.id,
                          collectionType: ECollectionType.PARTY,
                          vote: EContentVote.COOL,
                        };
                        dispatch(voteOnPartyContentAsync(coolVote));
                      }}
                    >
                      cool
                    </button>
                  )}
                  {image.content.explorerVote !== EContentVote.ULTRA_COOL && (
                    <button
                      type="button"
                      className="relative -ml-px inline-flex items-center rounded-r-md bg-pink-400 px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                      onClick={() => {
                        const coolVote: ContentVoteDto = {
                          collectionId: collectionId,
                          contentId: image.content.id,
                          collectionType: ECollectionType.PARTY,
                          vote: EContentVote.ULTRA_COOL,
                        };
                        dispatch(voteOnPartyContentAsync(coolVote));
                      }}
                    >
                      🔥 ULTRA COOL 🔥
                    </button>
                  )}
                </span>
              </div>
            ))}
        </div>
      </div>
      {viewImageIndex > -1 && (
        <ViewPartyImageModal
          imageIndex={viewImageIndex}
          onIndexChange={setViewImageIndex}
          collection={contents}
          collectionId={collectionId}
        />
      )}
    </div>
  );
};
